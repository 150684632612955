export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const casinoSection = {
  popularGames: {
    AndarBaharVivoPplr: {
      redirectUrl: "/casino/vivo-andar-bahar",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/AndarBaharVivo.jpg",
      alt: "andar bahar",
      name: "Andar Bahar",
      providerName: "Vivo",
    },
    LasVegasBlackjackVivoPplr: {
      redirectUrl: "/casino/vivo-las-vegas-blackjack",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/vivo-las-vegas-blackjack.jpg",
      alt: "las vegas blackjack",
      name: "Las Vegas Blackjack",
      providerName: "Vivo",
    },
    TeenPattiVivoPplr: {
      redirectUrl: "/casino/vivo-teen-patti",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/TeenPattiVivo.jpg",
      alt: "teen patti",
      name: "Teen Patti",
      providerName: "Vivo",
    },

    aviator: {
      redirectUrl: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Aviator.gif",
      alt: "",
      name: "Aviator",
      providerName: "Spribe",
    },

    AutoRoulettePplr: {
      redirectUrl: "/casino/ezg-auto-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/autoroulette.jpg",
      alt: "",
      name: "Auto Roulette",
      providerName: "Ezugi",
    },
    UltimateRoulettePplr: {
      redirectUrl: "/casino/ezg-ultimate-roulette",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/UltimateRoulette.jpg",
      alt: "",
      name: "Ultimate Roulette",
      providerName: "Ezugi",
    },
    CricketAutoRoulettePplr: {
      redirectUrl: "/casino/ezg-cricket-auto-roulette",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/CricketAutoRoulette.jpg",
      alt: "",
      name: "Cricket Auto Roulette",
      providerName: "Ezugi",
    },
    UltimateAndarBaharPplr: {
      redirectUrl: "/casino/ezg-ultimate-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/ultimateAB.jpg",
      alt: "",
      name: "Ultimate Andar Bahar",
      providerName: "Ezugi",
    },
    AndarBaharPplr: {
      redirectUrl: "/casino/ezg-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/andarbahar.jpg",
      alt: "",
      name: "Andar Bahar",
      providerName: "Ezugi",
    },
    DragonTigerPplr: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/Dragontigerr.jpg",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Ezugi",
    },
    NoCommissionBaccaratPplr: {
      redirectUrl: "/casino/ezg-no-commission-baccarat",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/no-commission-baccarat.jpg",
      alt: "",
      name: "No Commission Baccarat",
      providerName: "Ezugi",
    },
    Lucky7Pplr: {
      redirectUrl: "/casino/ezg-lucky-7",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/lucky.jpg",
      alt: "",
      name: "Lucky 7",
      providerName: "Ezugi",
    },
    Onedayteenpatti1Pplr: {
      redirectUrl: "/casino/ezg-one-day-teen-patti",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Onedayteenpatti.jpg",
      alt: "",
      name: "One Day Teen Patti",
      providerName: "Ezugi",
    },
    CricketWarPplr: {
      redirectUrl: "/casino/ezg-cricket-war",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/CricketWar.jpg",
      alt: "",
      name: "Cricket War",
      providerName: "Ezugi",
    },

    InstantRouletteEvoPplr: {
      redirectUrl: "/casino/ezgevo-instant-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/InstantRoulette.jpg",
      alt: "",
      name: "instant Roulette",
      providerName: "Evolution",
    },
    AutoRouletteEvoPplr: {
      redirectUrl: "/casino/ezgevo-auto-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/AutoRoulette.jpg",
      alt: "",
      name: "Auto Roulette",
      providerName: "Evolution",
    },
    SpeedRouletteEvoPplr: {
      redirectUrl: "/casino/ezgevo-speed-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/SpeedRoulette.jpg",
      alt: "",
      name: "Speed Roulette",
      providerName: "Evolution",
    },
    XXXtremeLightningRouletteEvoPplr: {
      redirectUrl: "/casino/ezgevo-xxxtreme-lightning-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/XXStremeLightningRoulette.jpg",
      alt: "",
      name: "XXXtreme Lightning Roulette",
      providerName: "Evolution",
    },
    LightningBaccaratPplr: {
      redirectUrl: "/casino/ezgevo-lightning-baccarat",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/LightningBaccarat.jpg",
      alt: "",
      name: "Lightning Baccarat",
      providerName: "Evolution",
    },
    NoCommissionBaccaratEvoPplr: {
      redirectUrl: "/casino/ezgevo-no-commission-baccarat-evo",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/NoCommissionBaccarat.jpg",
      alt: "",
      name: "No Commission Baccarat Evo",
      providerName: "Evolution",
    },
    SpeedBaccaratAPplr: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-a",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/SpeedBaccaratA.jpg",
      alt: "",
      name: "Speed Baccarat A",
      providerName: "Evolution",
    },
    BlackjackAEvoPplr: {
      redirectUrl: "/casino/ezgevo-blackjack-a",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/BlackjackA.jpg",
      alt: "",
      name: "Blackjack A",
      providerName: "Evolution",
    },
    ClassicSpeedBlackjack10Pplr: {
      redirectUrl: "/casino/ezgevo-classic-speed-blackjack-10",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/ClassicSpeedBlackjack10.jpg",
      alt: "",
      name: "Classic Speed Blackjack 10",
      providerName: "Evolution",
    },
    powerBlackjackPplr: {
      redirectUrl: "/casino/ezgevo-power-blackjack",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/PowerBlackjack.jpg",
      alt: "",
      name: "Power Blackjack",
      providerName: "Evolution",
    },

    RNGCasinoQueenPplr: {
      redirectUrl: "/casino/sn-rng-casino-queen",
      code: "RCQ",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGCasinoQueen.jpg",
      alt: "",
      name: "RNG Casino Queen",
      providerName: "Supernowa",
    },
    RNGCasinoQueen2020Pplr: {
      redirectUrl: "/casino/sn-rng-casino-queen-2020",
      code: "RCQ20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGCasinoQueen2020.jpg",
      alt: "",
      name: "RNG Casino Queen 2020",
      providerName: "Supernowa",
    },
    RNGJokerPplr: {
      redirectUrl: "/casino/sn-rng-joker",
      code: "VJKR",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGJoker.jpg",
      alt: "",
      name: "RNG Joker",
      providerName: "Supernowa",
    },
    RNGKingRacePplr: {
      redirectUrl: "/casino/sn-rng-king-race",
      code: "VCR",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGKingRace.jpg",
      alt: "",
      name: "RNG King Race",
      providerName: "Supernowa",
    },
    RNGLucky7Pplr: {
      redirectUrl: "/casino/sn-rng-lucky-7",
      code: "VUD7",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGLucky7.jpg",
      alt: "",
      name: "RNG Lucky 7",
      providerName: "Supernowa",
    },
    RNGWorliMatkaPplr: {
      redirectUrl: "/casino/sn-rng-worli-matka",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGWorliMatka.jpg",
      alt: "",
      name: "RNG Worli Matka",
      providerName: "Supernowa",
    },
  },

  AndarBahar: {
    AndarBaharVivo: {
      redirectUrl: "/casino/vivo-andar-bahar",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/AndarBaharVivo.jpg",
      alt: "andar bahar",
      name: "Andar Bahar",
      providerName: "Vivo",
    },

    AndarBahar: {
      redirectUrl: "/casino/ezg-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/andarbahar.jpg",
      alt: "",
      name: "Andar Bahar",
      providerName: "Ezugi",
    },
    OTTAndarBahar: {
      redirectUrl: "/casino/ezg-casino-marina-andar-bahar	",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/OTTAndarBahar.jpg",
      alt: "",
      name: "OTT Andar Bahar",
      providerName: "Ezugi",
    },
    UltimateAndarBahar: {
      redirectUrl: "/casino/ezg-ultimate-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/ultimateAB.jpg",
      alt: "",
      name: "Ultimate Andar Bahar",
      providerName: "Ezugi",
    },
    GoasAndarBaharSN: {
      redirectUrl: "/casino/sn-goas-andar-bahar",
      code: "GAB",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/GoasAndarBahar.jpg",
      alt: "",
      name: "Goas Andar Bahar",
      providerName: "Supernowa",
    },
    AndarBaharSN: {
      redirectUrl: "/casino/sn-andar-bahar",
      code: "AB",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/AndarBahar.jpg",
      alt: "",
      name: "Andar Bahar",
      providerName: "Supernowa",
    },
    RNGAndarBahar2020: {
      redirectUrl: "/casino/sn-rng-andar-bahar-2020",
      code: "RAB20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGAndarBahar2020.jpg",
      alt: "",
      name: "RNG Andar Bahar 2020",
      providerName: "Supernowa",
    },
    ClassicAndarBaharSN: {
      redirectUrl: "/casino/sn-classic-andar-bahar",
      code: "CAB",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/ClassicAndarBahar.jpg",
      alt: "",
      name: "Classic Andar Bahar",
      providerName: "Supernowa",
    },
  },

  baccarattab: {
    Baccarat: {
      redirectUrl: "/casino/ezg-baccarat",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/Baccarat.jpg",
      alt: "",
      name: "Baccarat",
      providerName: "Ezugi",
    },
    FortuneBaccarat: {
      redirectUrl: "/casino/ezg-fortune-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/FortuneBaccarat.png",
      alt: "",
      name: "Fortune Baccarat",
      providerName: "Ezugi",
    },
    VipFortuneBaccarat: {
      redirectUrl: "/casino/ezg-vip-fortune-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/VIPFortuneBaccarat.jpg",
      alt: "",
      name: "Vip Fortune Baccarat",
      providerName: "Ezugi",
    },
    SpeedFortuneBaccarat: {
      redirectUrl: "/casino/ezg-speed-fortune-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/SpeedFortuneBaccarat.jpg",
      alt: "",
      name: "Speed Fortune Baccarat",
      providerName: "Ezugi",
    },
    OTTBaccarat1: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/CasinoMarinaBaccarat1.jpg",
      alt: "",
      name: "Casino Marina Baccarat 1",
      providerName: "Ezugi",
    },
    OTTBaccarat2: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-2",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/CasinoMarinaBaccarat2.jpg",
      alt: "",
      name: "Casino Marina Baccarat 2",
      providerName: "Ezugi",
    },
    OTTBaccarat3: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-3",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/CasinoMarinaBaccarat3.jpg",
      alt: "",
      name: "Casino Marina Baccarat 3",
      providerName: "Ezugi",
    },
    Super6Baccarat: {
      redirectUrl: "/casino/ezg-super-6-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/Super6Baccarat.jpg",
      alt: "",
      name: "Super 6 Baccarat",
      providerName: "Ezugi",
    },
    KnockoutBaccarat: {
      redirectUrl: "/casino/ezg-knockout-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/KnockoutBaccarat.jpg",
      alt: "",
      name: "Knockout Baccarat",
      providerName: "Ezugi",
    },
    NoCommissionBaccarat: {
      redirectUrl: "/casino/ezg-no-commission-baccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/NoCommissionBaccarat.jpg",
      alt: "",
      name: "No Commission Baccarat",
      providerName: "Ezugi",
    },
    OverUnderBaccarat: {
      redirectUrl: "/casino/ezg-over-under-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/OverUnderBaccarat.jpg",
      alt: "",
      name: "Over Under Baccarat",
      providerName: "Ezugi",
    },
    NoCommissionBaccaratA: {
      redirectUrl: "/casino/ezg-no-commission-baccarat-a",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/NoCommissionBaccaratA.jpg",
      alt: "",
      name: "No Commission Baccarat A",
      providerName: "Ezugi",
    },
    NoCommissionBaccaratB: {
      redirectUrl: "/casino/ezg-no-commission-baccarat-b",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/NoCommissionBaccaratB.jpg",
      alt: "",
      name: "No Commission Baccarat B",
      providerName: "Ezugi",
    },
    NoCommissionOverUnderBaccarat: {
      redirectUrl: "/casino/ezg-no-commission-over-under-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/NoCommissionOverUnderBaccarat.jpg",
      alt: "",
      name: "No Commission Over Under Baccarat",
      providerName: "Ezugi",
    },
    BaccaratE: {
      redirectUrl: "/casino/ezg-baccarat-e",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/BaccaratE.jpg",
      alt: "",
      name: "Baccarat E",
      providerName: "Ezugi",
    },

    BaccaratB: {
      redirectUrl: "/casino/ezgevo-baccarat-b",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/BaccaratB.jpg",
      alt: "",
      name: "Baccarat B",
      providerName: "Evolution",
    },
    BaccaratA: {
      redirectUrl: "/casino/ezgevo-baccarat-a",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/BaccaratA.jpg",
      alt: "",
      name: "Baccarat A",
      providerName: "Evolution",
    },
    BaccaratControlSqueeze: {
      redirectUrl: "/casino/ezgevo-baccarat-control-squeeze",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/BaccaratControlSqueeze.jpg",
      alt: "",
      name: "Baccarat Control Squeeze",
      providerName: "Evolution",
    },
    BaccaratSqueeze: {
      redirectUrl: "/casino/ezgevo-baccarat-squeeze",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/BaccaratSqueeze.jpg",
      alt: "",
      name: "Baccarat Squeeze",
      providerName: "Evolution",
    },
    FirstPersonBaccarat: {
      redirectUrl: "/casino/ezgevo-first-person-baccarat",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/FirstPersonBaccarat.jpg",
      alt: "",
      name: "First Person Baccarat",
      providerName: "Evolution",
    },
    FirstPersonGoldenWealthBaccarat: {
      redirectUrl: "/casino/ezgevo-first-person-golden-wealth-baccarat",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/FirstPersonGoldenWealthBaccarat.jpg",
      alt: "",
      name: "First Person Golden Wealth Baccarat",
      providerName: "Evolution",
    },
    FirstPersonLightningBaccarat: {
      redirectUrl: "/casino/ezgevo-first-person-lightning-baccarat",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/FirstPersonLightningBaccarat.jpg",
      alt: "",
      name: "First Person Lightning Baccarat",
      providerName: "Evolution",
    },
    GoldenWealthBaccarat: {
      redirectUrl: "/casino/ezgevo-golden-wealth-baccarat",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/GoldenWealthBaccarat.jpg",
      alt: "",
      name: "Golden Wealth Baccarat",
      providerName: "Evolution",
    },
    LightningBaccarat: {
      redirectUrl: "/casino/ezgevo-lightning-baccarat",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/LightningBaccarat.jpg",
      alt: "",
      name: "Lightning Baccarat",
      providerName: "Evolution",
    },
    NoCommissionBaccaratEvo: {
      redirectUrl: "/casino/ezgevo-no-commission-baccarat-evo",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/NoCommissionBaccarat.jpg",
      alt: "",
      name: "No Commission Baccarat Evo",
      providerName: "Evolution",
    },
    SpeedBaccaratA: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-a",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/SpeedBaccaratA.jpg",
      alt: "",
      name: "Speed Baccarat A",
      providerName: "Evolution",
    },
    SpeedBaccaratB: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-b",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/SpeedBaccaratB.jpg",
      alt: "",
      name: "Speed Baccarat B",
      providerName: "Evolution",
    },
    SpeedBaccaratD: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-d",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/SpeedBaccaratD.jpg",
      alt: "",
      name: "Speed Baccarat D",
      providerName: "Evolution",
    },
    SpeedBaccaratR: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-r",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/SpeedBaccaratR.jpg",
      alt: "",
      name: "Speed Baccarat R",
      providerName: "Evolution",
    },
    SpeedBaccaratX: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-x",
      code: "101",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/SpeedBaccaratX.jpg",
      alt: "",
      name: "Speed Baccarat X",
      providerName: "Evolution",
    },

    MacauBaccaratVivo: {
      redirectUrl: "/casino/vivo-macau-baccarat",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/vivo-macau-baccarat.jpg",
      alt: "macau baccarat",
      name: "Macau Baccarat",
      providerName: "Vivo",
    },
    VABacarat1: {
      redirectUrl: "/casino/vivo-va-bacarat-1",
      imgUrl: "./images/Vivo/VABacarat1.jpg",
      name: "VA Bacarat 1",
      providerName: "Vivo",
    },
    VABacarat2: {
      redirectUrl: "/casino/vivo-va-bacarat-2",
      imgUrl: "./images/Vivo/VABacarat2.jpg",
      name: "VA Bacarat 2",
      providerName: "Vivo",
    },
    VABacarat3: {
      redirectUrl: "/casino/vivo-va-bacarat-3",
      imgUrl: "./images/Vivo/VABacarat3.jpg",
      name: "VA Bacarat 3",
      providerName: "Vivo",
    },
    VABacarat4: {
      redirectUrl: "/casino/vivo-va-bacarat-4",
      imgUrl: "./images/Vivo/VABacarat4.jpg",
      name: "VA Bacarat 4",
      providerName: "Vivo",
    },
    VABacarat5: {
      redirectUrl: "/casino/vivo-va-bacarat-5",
      imgUrl: "./images/Vivo/VABacarat5.jpg",
      name: "VA Bacarat 5",
      providerName: "Vivo",
    },
  },

  blackjacktab: {
    BlackjackA: {
      redirectUrl: "/casino/ezg-blackjack-a",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ezugi/BlackjackA.jpg",
      alt: "",
      name: "Blackjack A",
      providerName: "Ezugi",
    },
    GoldBlackjack3: {
      redirectUrl: "/casino/ezg-gold-blackjack-3",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ezugi/GoldBlackjack3.jpg",
      alt: "",
      name: "Gold Blackjack 3",
      providerName: "Ezugi",
    },
    GoldBlackjack1: {
      redirectUrl: "/casino/ezg-gold-blackjack-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ezugi/GoldBlackjack1.jpg",
      alt: "",
      name: "Gold Blackjack 1",
      providerName: "Ezugi",
    },
    VipSurrenderBlackjack: {
      redirectUrl: "/casino/ezg-vip-surrender-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ezugi/VipSurrenderBlackjack.jpg",
      alt: "",
      name: "Vip Surrender Blackjack",
      providerName: "Ezugi",
    },
    VipDiamondBlackjack: {
      redirectUrl: "/casino/ezg-vip-diamond-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ezugi/VIPDiamondBlackjack.jpg",
      alt: "",
      name: "Vip Diamond Blackjack",
      providerName: "Ezugi",
    },
    RumbaBlackjack1: {
      redirectUrl: "/casino/ezg-rumba-blackjack-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ezugi/RumbaBlackjack1.jpg",
      alt: "",
      name: "Rumba Blackjack 1",
      providerName: "Ezugi",
    },
    RumbaBlackjack4: {
      redirectUrl: "/casino/ezg-rumba-blackjack-4",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ezugi/RumbaBlackjack4.jpg",
      alt: "",
      name: "Rumba Blackjack 4",
      providerName: "Ezugi",
    },
    UnlimitedBlackjack: {
      redirectUrl: "/casino/ezg-unlimited-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ezugi/UnlimitedBlackjack.jpg",
      alt: "",
      name: "Unlimited Blackjack",
      providerName: "Ezugi",
    },
    SpanishUnlimitedBlackjack: {
      redirectUrl: "/casino/ezg-spanish-unlimited-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ezugi/SpanishUnlimitedBlackjack.jpg",
      alt: "",
      name: "Spanish Unlimited Blackjack",
      providerName: "Ezugi",
    },
    BlackjackDaSorte: {
      redirectUrl: "/casino/ezg-blackjack-da-sorte",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ezugi/BlackjackDaSorte.jpg",
      alt: "",
      name: "Blackjack Da Sorte",
      providerName: "Ezugi",
    },

    BlackjackAEvo: {
      redirectUrl: "/casino/ezgevo-blackjack-a",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/BlackjackA.jpg",
      alt: "",
      name: "Blackjack A",
      providerName: "Evolution",
    },
    BlackjackParty: {
      redirectUrl: "/casino/ezgevo-blackjack-party",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/BlackjackParty.jpg",
      alt: "",
      name: "Blackjack Party",
      providerName: "Evolution",
    },
    BlackjackSilverA: {
      redirectUrl: "/casino/ezgevo-blackjack-silver-a",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/BlackjackSilverA.jpg",
      alt: "",
      name: "Blackjack Silver A",
      providerName: "Evolution",
    },
    BlackjackVip10: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-10",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/BlackjackVIP10.jpg",
      alt: "",
      name: "Blackjack Vip 10",
      providerName: "Evolution",
    },
    BlackjackVipAlpha: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-alpha",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/BlackjackVipAlpha.jpg",
      alt: "",
      name: "Blackjack Vip Alpha",
      providerName: "Evolution",
    },
    BlackjackVipBeta: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-beta",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/BlackjackVipBeta.jpg",
      alt: "",
      name: "Blackjack Vip Beta",
      providerName: "Evolution",
    },
    BlackjackVipGamma: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-gamma",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/BlackjackVipGamma.jpg",
      alt: "",
      name: "Blackjack Vip Gamma",
      providerName: "Evolution",
    },
    ClassicSpeedBlackjack10: {
      redirectUrl: "/casino/ezgevo-classic-speed-blackjack-10",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/ClassicSpeedBlackjack10.jpg",
      alt: "",
      name: "Classic Speed Blackjack 10",
      providerName: "Evolution",
    },
    FirstPersonBlackjack: {
      redirectUrl: "/casino/ezgevo-first-person-blackjack",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/FirstPersonBlackjack.jpg",
      alt: "",
      name: "First Person Blackjack 1",
      providerName: "Evolution",
    },
    FirstPersonLightningBlackjack: {
      redirectUrl: "/casino/ezgevo-first-person-lightning-blackjack",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/FirstPersonLigghtningBlackjack.jpg",
      alt: "",
      name: "First Person Lightning Blackjack",
      providerName: "Evolution",
    },
    InfiniteBlackjack: {
      redirectUrl: "/casino/ezgevo-infinite-blackjack",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/InfiniteBlackjack.jpg",
      alt: "",
      name: "Infinite Blackjack",
      providerName: "Evolution",
    },
    LightningBlackjack: {
      redirectUrl: "/casino/ezgevo-lightning-blackjack",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/LightningBlackjack.jpg",
      alt: "",
      name: "Lightning Blackjack",
      providerName: "Evolution",
    },
    powerBlackjack: {
      redirectUrl: "/casino/ezgevo-power-blackjack",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/PowerBlackjack.jpg",
      alt: "",
      name: "Power Blackjack",
      providerName: "Evolution",
    },
    SpeedBlackjackD: {
      redirectUrl: "/casino/ezgevo-speed-blackjack-d",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/SpeedBlackjackD.jpg",
      alt: "",
      name: "Speed Blackjack D",
      providerName: "Evolution",
    },
    SpeedVipBlackjackA: {
      redirectUrl: "/casino/ezgevo-speed-vip-blackjack-a",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/SpeedVIPBlackjackA.jpg",
      alt: "",
      name: "Speed V  ip Blackjack A",
      providerName: "Evolution",
    },

    LasVegasBlackjackVivo: {
      redirectUrl: "/casino/vivo-las-vegas-blackjack",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/vivo-las-vegas-blackjack.jpg",
      alt: "las vegas blackjack",
      name: "Las Vegas Blackjack",
      providerName: "Vivo",
    },
  },

  roulettetab: {
    ItalianRoulette: {
      redirectUrl: "/casino/ezg-italian-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/ItalianRoulette.jpg",
      alt: "",
      name: "Italian Roulette",
      providerName: "Ezugi",
    },
    SpeedRoulette: {
      redirectUrl: "/casino/ezg-speed-roulette",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/SpeedRoulette.jpg",
      alt: "",
      name: "Speed Roulette",
      providerName: "Ezugi",
    },
    NamasteRoulette: {
      redirectUrl: "/casino/ezg-namaste-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/NamasteRoulette.jpg",
      alt: "",
      name: "Namaste Roulette",
      providerName: "Ezugi",
    },
    DiamondRoulette: {
      redirectUrl: "/casino/ezg-diamond-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/DiamondRoulette.jpg",
      alt: "",
      name: "Diamond Roulette",
      providerName: "Ezugi",
    },
    SpanishRoulette: {
      redirectUrl: "/casino/ezg-spanish-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/SpanishRoulette.jpg",
      alt: "",
      name: "Spanish Roulette",
      providerName: "Ezugi",
    },
    TurkishRoulette: {
      redirectUrl: "/casino/ezg-turkish-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/TurkishRoulette.jpg",
      alt: "",
      name: "Turkish Roulette",
      providerName: "Ezugi",
    },
    RussianRoulette: {
      redirectUrl: "/casino/ezg-russian-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/RussianRoulette.jpg",
      alt: "",
      name: "Russian Roulette",
      providerName: "Ezugi",
    },
    AutoRoulette: {
      redirectUrl: "/casino/ezg-auto-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/AutoRoulette.jpg",
      alt: "",
      name: "Auto Roulette",
      providerName: "Ezugi",
    },
    SpeedAutoRoulette: {
      redirectUrl: "/casino/ezg-speed-auto-roulette",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/SpeedAutoRoulette.jpg",
      alt: "",
      name: "Speed Auto Roulette",
      providerName: "Ezugi",
    },
    CasinoMarinaRoulette1: {
      redirectUrl: "/casino/ezg-casino-marina-roulette-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/CasinoMarinaRoulette1.jpg",
      alt: "",
      name: "Casino Marina Roulette 1",
      providerName: "Ezugi",
    },
    CasinoMarinaRoulette2: {
      redirectUrl: "/casino/ezg-casino-marina-roulette-2",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/CasinoMarinaRoulette2.jpg",
      alt: "",
      name: "Casino Marina Roulette 2",
      providerName: "Ezugi",
    },
    RuletaDelSol: {
      redirectUrl: "/casino/ezg-ruleta-del-sol",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/RuletaDelSol.jpg",
      alt: "",
      name: "Ruleta Del Sol",
      providerName: "Ezugi",
    },
    FirstPersonAmericanRoulette: {
      redirectUrl: "/casino/ezg-first-person-american-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/FirstPersonAmericanRoulette.jpg",
      alt: "",
      name: "First Person American Roulette",
      providerName: "Ezugi",
    },
    UltimateRoulette: {
      redirectUrl: "/casino/ezg-ultimate-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/UltimateRoulette.jpg",
      alt: "",
      name: "Ultimate Roulette",
      providerName: "Ezugi",
    },
    CricketAutoRoulette: {
      redirectUrl: "/casino/ezg-cricket-auto-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/CricketAutoRoulette.jpg",
      alt: "",
      name: "Cricket Auto Roulette",
      providerName: "Ezugi",
    },
    SkylineRoulette: {
      redirectUrl: "/casino/ezg-skyline-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/SkylineRoulette.jpg",
      alt: "",
      name: "Skyline Roulette",
      providerName: "Ezugi",
    },
    FootballAutoRoulette: {
      redirectUrl: "/casino/ezg-football-auto-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/FootballAutoRoulette.jpg",
      alt: "",
      name: "Football Auto Roulette",
      providerName: "Ezugi",
    },
    RoletaDdaSorte: {
      redirectUrl: "/casino/ezg-roleta-da-sorte",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/RuletaDaSorte.jpg",
      alt: "",
      name: "Roleta Da Sorte",
      providerName: "Ezugi",
    },
    RuletaA: {
      redirectUrl: "/casino/ezg-ruleta-a",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/RuletaA.jpg",
      alt: "",
      name: "Ruleta A",
      providerName: "Ezugi",
    },
    VipRoulette: {
      redirectUrl: "/casino/ezg-vip-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/VIPRoulette.jpg",
      alt: "",
      name: "Vip Roulette",
      providerName: "Ezugi",
    },

    FirstPersonAmericanRouletteEvo: {
      redirectUrl: "/casino/ezgevo-first-person-american-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/FirstPersonAmericanRoulette.jpg",
      alt: "",
      name: "First Person American Roulette",
      providerName: "Evolution",
    },
    InstantRouletteEvo: {
      redirectUrl: "/casino/ezgevo-instant-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/InstantRoulette.jpg",
      alt: "",
      name: "instant Roulette",
      providerName: "Evolution",
    },
    TurkishLightningRouletteEvo: {
      redirectUrl: "/casino/ezgevo-turkish-lightning-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/TurkishLightningRoulette.jpg",
      alt: "",
      name: "Turkish Lightning Roulette",
      providerName: "Evolution",
    },
    AmericanRouletteEvo: {
      redirectUrl: "/casino/ezgevo-american-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/AmericanRoulette.jpg",
      alt: "",
      name: "American Roulette",
      providerName: "Evolution",
    },
    AutoRouletteEvo: {
      redirectUrl: "/casino/ezgevo-auto-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/AutoRoulette.jpg",
      alt: "",
      name: "Auto Roulette",
      providerName: "Evolution",
    },
    DoubleBallRouletteEvo: {
      redirectUrl: "/casino/ezgevo-double-ball-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/DoubleBallRoulette.jpg",
      alt: "",
      name: "Double Ball Roulette",
      providerName: "Evolution",
    },
    AutoRouletteVipEvo: {
      redirectUrl: "/casino/ezgevo-auto-roulette-vip",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/AutoRouletteVIP.jpg",
      alt: "",
      name: "Auto Roulette Vip",
      providerName: "Evolution",
    },
    DragonaraRouletteEvo: {
      redirectUrl: "/casino/ezgevo-dragonara-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/DragonaraRoulette.jpg",
      alt: "",
      name: "Dragonara Roulette",
      providerName: "Evolution",
    },
    FootballStudioRouletteEvo: {
      redirectUrl: "/casino/ezgevo-football-studio-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/FootballStudioRoulette.jpg",
      alt: "",
      name: "Football Studio Roulette",
      providerName: "Evolution",
    },
    RouletteEvo: {
      redirectUrl: "/casino/ezgevo-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/Roulette.jpg",
      alt: "",
      name: "Roulette",
      providerName: "Evolution",
    },
    LightningRouletteEvo: {
      redirectUrl: "/casino/ezgevo-lightning-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/LightningRoulette.jpg",
      alt: "",
      name: "Lightning Roulette",
      providerName: "Evolution",
    },
    SpeedRouletteEvo: {
      redirectUrl: "/casino/ezgevo-speed-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/SpeedRoulette.jpg",
      alt: "",
      name: "Speed Roulette",
      providerName: "Evolution",
    },
    SpeedAutoRouletteEvo: {
      redirectUrl: "/casino/ezgevo-speed-auto-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/SpeedAutoRoulette.jpg",
      alt: "",
      name: "Speed Auto Roulette",
      providerName: "Evolution",
    },
    XXXtremeLightningRouletteEvo: {
      redirectUrl: "/casino/ezgevo-xxxtreme-lightning-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/XXStremeLightningRoulette.jpg",
      alt: "",
      name: "XXXtreme Lightning Roulette",
      providerName: "Evolution",
    },
    VipRouletteEvo: {
      redirectUrl: "/casino/ezgevo-vip-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/VipRoulette.jpg",
      alt: "",
      name: "Vip Roulette",
      providerName: "Evolution",
    },

    PortomasoRouletteVivo: {
      redirectUrl: "/casino/vivo-portomaso-roulette",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/vivo-portomaso-roulette.jpg",
      alt: "portomaso roulette",
      name: "Portomaso Roulette",
      providerName: "Vivo",
    },
    OracleBlazeRouletteVivo: {
      redirectUrl: "/casino/vivo-oracle-blaze-roulette",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/vivo-oracle-blaze-roulette.jpg",
      alt: "oracle blaze roulette",
      name: "Oracle Blaze Roulette",
      providerName: "Vivo",
    },
    LasVegasRouletteVivo: {
      redirectUrl: "/casino/vivo-las-vegas-roulette",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/vivo-las-vegas-roulette.jpg",
      alt: "las vegas roulette",
      name: "Las Vegas Roulette",
      providerName: "Vivo",
    },
  },

  dragontiger: {
    DragonTiger: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/Dragontigerr.jpg",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Ezugi",
    },
    DragonTigerEvolution: {
      redirectUrl: "/casino/ezgevo-dragon-tiger-evo",
      code: "1000148",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/Dragontigerrevo.jpg",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Evolution",
    },
    DragonTigerVivo: {
      redirectUrl: "/casino/vivo-dragon-tiger",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/vivo-dragon-tiger.jpg",
      alt: "dragon tiger",
      name: "Dragon Tiger",
      providerName: "Vivo",
    },
    RNGDragonTiger: {
      redirectUrl: "/casino/sn-rng-dragon-tiger",
      code: "VDT",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNG-Dragon-Tiger.jpg",
      alt: "",
      name: "RNG Dragon Tiger",
      providerName: "Supernowa",
    },
    RNGDragonTiger2020: {
      redirectUrl: "/casino/sn-rng-dragon-tiger-2020",
      code: "VDT20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGDragonTiger2020.jpg",
      alt: "",
      name: "RNG Dragon Tiger 2020",
      providerName: "Supernowa",
    },
  },

  pokertab: {
    Hand2CasinoHoldem: {
      redirectUrl: "/casino/ezgevo-2-hand-casino-holdem",
      code: "1000073",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/2handcasinoholem.jpg",
      alt: "",
      name: " 2 Hand Casino Holdem",
      providerName: "Evolution",
    },
    BetonTeenPatti: {
      redirectUrl: "/casino/ezg-bet-on-teen-patti",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Betonteenpatti.jpg",
      alt: "",
      name: "Bet on Teen Patti",
      providerName: "Ezugi",
    },
    // SideBetCity: {
    //   redirectUrl: "/casino/ezugi/SideBetCity",
    //   code: "1000115",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/poker/Sidebetcity.jpg",
    //   alt: "",
    //   name: "Side Bet City",
    //   providerName: "Ezugi",
    // },
    TeenPatti3card: {
      redirectUrl: "/casino/ezugi/TeenPatti3card",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Teenpatti3card.jpg",
      alt: "",
      name: " Teen Patti 3 Card",
      providerName: "Ezugi",
    },
    TexasHoldembonus: {
      redirectUrl: "/casino/ezgevo-texas-holdem-bonus-poker",
      code: "1000111",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/TexasHoldembonus.jpg",
      alt: "",
      name: "Texas Holdem Bonus",
      providerName: "Evolution",
    },
    // Ultimatetexasholdem: {
    //   redirectUrl: "/casino/ezugi/Ultimatetexasholdem",
    //   code: "1000151",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/poker/ultimatetaxasholden.jpg",
    //   alt: "",
    //   name: "Ultimate Texas Holdem",
    //   providerName: "Ezugi",
    // },
    RNGTeenPatti: {
      redirectUrl: "/casino/sn-rng-teen-patti",
      code: "VTP",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGTeenPatti.jpg",
      alt: "",
      name: "RNG Teen Patti",
      providerName: "Supernowa",
    },
    RNGTeenPatti2020: {
      redirectUrl: "/casino/sn-rng-teen-patti-2020",
      code: "VTP20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGTeenPatti2020.jpg",
      alt: "",
      name: "RNG Teen Patti 2020",
      providerName: "Supernowa",
    },
  },

  tvShows: {
    Blackjack: {
      redirectUrl: "/casino/ezg-blackjack-1",
      code: "201",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack.jpg",
      alt: "",
      name: "Blackjack",
      providerName: "Ezugi",
    },
    LightningDice: {
      redirectUrl: "/casino/ezgevo-lightning-dice",
      code: "1000148",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/lightningdice.jpg",
      alt: "",
      name: "Lightning Dice",
      providerName: "Evolution",
    },
    CrazyTime: {
      redirectUrl: "/casino/ezugi/CrazyTime",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/CrazyTime.jpg",
      alt: "",
      name: "Crazy Time",
      providerName: "Ezugi",
    },
    Megaball: {
      redirectUrl: "/casino/ezgevo-mega-ball",
      code: "1000141",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/Megaball.jpg",
      alt: "",
      name: "Megaball",
      providerName: "Ezugi",
    },
  },

  spribe: {
    aviator: {
      redirectUrl: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Aviator.gif",
      alt: "",
      name: "Aviator",
      providerName: "Spribe",
    },
    mines: {
      redirectUrl: "/casino/spribe/mines",
      code: "mines",
      casino: "spribe",
      provider: "mines",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Mines.jpg",
      alt: "",
      name: "Mines",
      providerName: "Spribe",
    },
    goal: {
      redirectUrl: "/casino/spribe/goal",
      code: "goal",
      casino: "spribe",
      provider: "goal",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Goal.jpg",
      alt: "",
      name: "Goal",
      providerName: "Spribe",
    },
    dice: {
      redirectUrl: "/casino/spribe/dice",
      code: "dice",
      casino: "spribe",
      provider: "dice",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Dice.jpg",
      alt: "",
      name: "Dice",
      providerName: "Spribe",
    },
    plinko: {
      redirectUrl: "/casino/spribe/plinko",
      code: "plinko",
      casino: "spribe",
      provider: "plinko",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Plinko.jpg",
      alt: "",
      name: "Plinko",
      providerName: "Spribe",
    },
    miniroulette: {
      redirectUrl: "/casino/spribe/miniroulette",
      code: "mini-roulette",
      casino: "spribe",
      provider: "mini-roulette",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/MiniROulette.jpg",
      alt: "",
      name: "Mini Roulette",
      providerName: "Spribe",
    },
    hilo: {
      redirectUrl: "/casino/spribe/hilo",
      code: "hi-lo",
      casino: "spribe",
      provider: "hi-lo",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Hilo.jpg",
      alt: "",
      name: "Hilo",
      providerName: "Spribe",
    },
  },

  kingmakerGames: {
    CardPoker5: {
      redirectUrl: "/casino/km-5-card-poker",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/5-card-poker.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Card Poker",
      providerName: "Kingmaker",
    },
    up7down7: {
      redirectUrl: "/casino/km-7-up-7-down",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/7-up-7-down.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "7 up 7 down",
      providerName: "Kingmaker",
    },
    cards32: {
      redirectUrl: "/casino/km-32-cards",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/32-cards.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "32 Cards",
      providerName: "Kingmaker",
    },
    AndarBahar: {
      redirectUrl: "/casino/km-andar-bahar",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/andar-bahar.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Andar Bahar",
      providerName: "Kingmaker",
    },
    Baccarat: {
      redirectUrl: "/casino/km-baccarat",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/baccarat.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Baccarat",
      providerName: "Kingmaker",
    },
    BaiBuu: {
      redirectUrl: "/casino/km-bai-buu",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/bai-buu.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Bai Buu",
      providerName: "Kingmaker",
    },
    BaiCao: {
      redirectUrl: "/casino/km-bai-cao",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/bai-cao.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Bai Cao",
      providerName: "Kingmaker",
    },
    Belangkai2: {
      redirectUrl: "/casino/km-belangkai-2",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/belangkai-2.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Belangkai 2",
      providerName: "Kingmaker",
    },
    Blackjack: {
      redirectUrl: "/casino/km-blackjack",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/blackjack.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Blackjack",
      providerName: "Kingmaker",
    },
    BolaGolek: {
      redirectUrl: "/casino/km-bola-golek",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/bola-golek.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Bola Golek",
      providerName: "Kingmaker",
    },
    BolaTangkas: {
      redirectUrl: "/casino/km-bola-tangkas",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/bola-tangkas.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Bola Tangkas",
      providerName: "Kingmaker",
    },
    BonusDice: {
      redirectUrl: "/casino/km-bonus-dice",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/bonus-dice.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Bonus Dice",
      providerName: "Kingmaker",
    },
    CardHiLo: {
      redirectUrl: "/casino/km-card-hi-lo",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/card-hi-lo.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Card Hi Lo",
      providerName: "Kingmaker",
    },
    CashRocket: {
      redirectUrl: "/casino/km-cash-rocket",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/cash-rocket.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Cash Rocket",
      providerName: "Kingmaker",
    },
    CleopatrasTreasure: {
      redirectUrl: "/casino/km-cleopatras-treasure",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/cleopatras-treasure.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Cleopatras Treasure",
      providerName: "Kingmaker",
    },
    CockfightingArena: {
      redirectUrl: "/casino/km-cockfighting-arena",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/cockfighting-arena.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Cockfighting Arena",
      providerName: "Kingmaker",
    },
    CoinPusher: {
      redirectUrl: "/casino/km-coin-pusher",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/coin-pusher.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Coin Pusher",
      providerName: "Kingmaker",
    },
    CoinToss: {
      redirectUrl: "/casino/km-coin-toss",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/coin-toss.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Coin Toss",
      providerName: "Kingmaker",
    },
    ColorGame: {
      redirectUrl: "/casino/km-color-game",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/color-game.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Color Game",
      providerName: "Kingmaker",
    },
    DotaHiLo: {
      redirectUrl: "/casino/km-dota-hi-lo",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/dota-hi-lo.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Dota Hi Lo",
      providerName: "Kingmaker",
    },
    DragonTiger2: {
      redirectUrl: "/casino/km-dragon-tiger-2",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/dragon-tiger-2.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Dragon Tiger 2",
      providerName: "Kingmaker",
    },
    EliteAviatorClub: {
      redirectUrl: "/casino/km-elite-aviator-club",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/elite-aviator-club.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Elite Aviator Club",
      providerName: "Kingmaker",
    },
    EuropeanRoulette: {
      redirectUrl: "/casino/km-european-roulette",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/european-roulette.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "European Roulette",
      providerName: "Kingmaker",
    },
    FanTvanClassic: {
      redirectUrl: "/casino/km-fan-tvan-classic",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/fan-tvan-classic.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Fan Tan Classic",
      providerName: "Kingmaker",
    },
    FishPrawnCrab2: {
      redirectUrl: "/casino/km-fish-prawn-crab-2",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/fish-prawn-crab-2.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Fish Prawn Crab 2",
      providerName: "Kingmaker",
    },
    FruitRoulette: {
      redirectUrl: "/casino/km-fruit-roulette",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/fruit-roulette.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "FruitRoulette",
      providerName: "Kingmaker",
    },
    GemsOfValhalla: {
      redirectUrl: "/casino/km-gems-of-valhalla",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/gems-of-valhalla.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Gems Of Valhalla",
      providerName: "Kingmaker",
    },
    GoldMines: {
      redirectUrl: "/casino/km-gold-mines",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/gold-mines.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Gold Mines",
      providerName: "Kingmaker",
    },
    Heist: {
      redirectUrl: "/casino/km-heist",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/heist.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "heist",
      providerName: "Kingmaker",
    },
    InterstellarRun: {
      redirectUrl: "/casino/km-interstellar-run",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/interstellar-run.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "InterstellarRun",
      providerName: "Kingmaker",
    },
    JackpotJump: {
      redirectUrl: "/casino/km-jackpot-jump",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/jackpot-jump.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Jackpot Jump",
      providerName: "Kingmaker",
    },
    JhandiMunda: {
      redirectUrl: "/casino/km-jhandi-munda",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/jhandi-munda.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Jhandi Munda",
      providerName: "Kingmaker",
    },
    KMPokDeng: {
      redirectUrl: "/casino/km-km-pok-deng",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/km-pok-deng.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "KM Pok Deng",
      providerName: "Kingmaker",
    },
    KMPowerBall: {
      redirectUrl: "/casino/km-km-power-ball",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/km-power-ball.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "KM Power Ball",
      providerName: "Kingmaker",
    },
    KMVirtualHorseRacing: {
      redirectUrl: "/casino/km-km-virtual-horse-racing",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/km-virtual-horse-racing.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "KM Virtual Horse Racing",
      providerName: "Kingmaker",
    },
    Ludo: {
      redirectUrl: "/casino/km-ludo",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/ludo.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Ludo",
      providerName: "Kingmaker",
    },
    MarbleRacing: {
      redirectUrl: "/casino/km-marble-racing",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/marble-racing.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Marble Racing",
      providerName: "Kingmaker",
    },
    MaxKeno: {
      redirectUrl: "/casino/km-max-keno",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/max-keno.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Max Keno",
      providerName: "Kingmaker",
    },
    Minesweeper: {
      redirectUrl: "/casino/km-minesweeper",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/minesweeper.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Minesweeper",
      providerName: "Kingmaker",
    },
    MonkeyKingRoulette: {
      redirectUrl: "/casino/km-monkey-king-roulette",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/monkey-king-roulette.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Monkey King Roulette",
      providerName: "Kingmaker",
    },
    OlympusGlory: {
      redirectUrl: "/casino/km-olympus-glory",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/olympus-glory.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Olympus Glory",
      providerName: "Kingmaker",
    },
    PaiKang: {
      redirectUrl: "/casino/km-pai-kang",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/pai-kang.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Pai Kang",
      providerName: "Kingmaker",
    },
    PenguinPanic: {
      redirectUrl: "/casino/km-penguin-panic",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/penguin-panic.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Penguin Panic",
      providerName: "Kingmaker",
    },
    Plinko: {
      redirectUrl: "/casino/km-plinko",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/plinko.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Plinko",
      providerName: "Kingmaker",
    },
    PokerRoulette: {
      redirectUrl: "/casino/km-poker-roulette",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/poker-roulette.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Poker Roulette",
      providerName: "Kingmaker",
    },
    Pusoy: {
      redirectUrl: "/casino/km-pusoy",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/pusoy.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Pusoy",
      providerName: "Kingmaker",
    },
    RoosterBlitz: {
      redirectUrl: "/casino/km-rooster-blitz",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/rooster-blitz.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Rooster Blitz",
      providerName: "Kingmaker",
    },
    SicBo: {
      redirectUrl: "/casino/km-sicbo",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/sicbo.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Rooster Blitz",
      providerName: "Kingmaker",
    },
    SugarBlast: {
      redirectUrl: "/casino/km-sugar-blast",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/sugar-blast.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Sugar Blast",
      providerName: "Kingmaker",
    },
    SugarBlastFrenzy: {
      redirectUrl: "/casino/km-sugar-blast-frenzy",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/sugar-blast-frenzy.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Sugar Blast Frenzy",
      providerName: "Kingmaker",
    },
    TaiXiu: {
      redirectUrl: "/casino/km-tai-xiu",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/tai-xiu.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Tai Xiu",
      providerName: "Kingmaker",
    },
    TeenPatti: {
      redirectUrl: "/casino/km-teen-patti",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/teen-patti.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Teen Patti",
      providerName: "Kingmaker",
    },
    ThaiFishPrawnCrab: {
      redirectUrl: "/casino/km-thai-fish-prawn-crab",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/thai-fish-prawn-crab.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Thai Fish Prawn Crab",
      providerName: "Kingmaker",
    },
    ThaiHiLo2: {
      redirectUrl: "/casino/km-thai-hi-lo-2",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/thai-hi-lo-2.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Thai Hi Lo 2",
      providerName: "Kingmaker",
    },
    Tongits: {
      redirectUrl: "/casino/km-tongits",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/tongits.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Tongits",
      providerName: "Kingmaker",
    },
    TripleChance: {
      redirectUrl: "/casino/km-triple-chance",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/triple-chance.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Triple Chance",
      providerName: "Kingmaker",
    },
    VideoPoker: {
      redirectUrl: "/casino/km-video-poker",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/video-poker.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Video Poker",
      providerName: "Kingmaker",
    },
    VietFishPrawnCrab: {
      redirectUrl: "/casino/km-viet-fish-prawn-crab",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/viet-fish-prawn-crab.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Viet Fish Prawn Crab",
      providerName: "Kingmaker",
    },
    VirtualAnimalRace: {
      redirectUrl: "/casino/km-virtual-animal-race",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/virtual-animal-race.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Virtual Animal Race",
      providerName: "Kingmaker",
    },
    VirtualGreyhoundRacing: {
      redirectUrl: "/casino/km-virtual-greyhound-racing",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/virtual-greyhound-racing.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Virtual Greyhound Racing",
      providerName: "Kingmaker",
    },
    VirtualTreadmillRacing: {
      redirectUrl: "/casino/km-virtual-treadmill-racing",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/virtual-treadmill-racing.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Virtual Treadmill Racing",
      providerName: "Kingmaker",
    },
    XocLightning: {
      redirectUrl: "/casino/km-xoc-lightning",
      code: "",
      casino: "kingmaker",
      imgUrl: "./images/kingmaker/xoc-lightning.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Xoc Lightning",
      providerName: "Kingmaker",
    },

    name: "Kingmaker",
    key: "kingmakerGames",
  },
};
