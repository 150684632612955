import React from "react";
import { useSelector } from "react-redux";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import HowWithdraw from "../../../../assets/images/howto/how-to-withdraw-banner.jpg";
import HowWithdrawMbl from "../../../../assets/images/howto/how-to-withdraw.png";

const HowToWithdraw = () => {
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main how-to-page how-withdraw">
        <img
          src={HowWithdraw}
          alt="how to join"
          className="d-none d-sm-block"
        />
        <img src={HowWithdrawMbl} alt="how to join" className="d-sm-none" />
        <div className="container">
          <div className="how-to-content">
            <div className="topHead">
              <strong>WELCOME USERS, </strong>
              <span>
                TODAY WE WILL LEARN HOW TO WITHDRAW EARNING ON Guru111
              </span>
            </div>

            <h4>How to Withdraw?</h4>
            <p>Login to your account first</p>
            <p>Click on "Withdraw": (Available in your profile menu as)</p>

            <p>
              <b>Follow the steps:</b>
            </p>
            <p>
              <b>Two Deposit Options are available</b>
            </p>
            <p>
              <b>1-Add Bank Details:</b> : (You can add your Bank details to use
              them later and permanently no need to enter again and again when
              you came to withdraw your funds)
            </p>
            <p>
              <b>2-Add Withdraw Request:</b>(Here you make a request to withdraw
              funds using added bank details or using UPI)
            </p>

            <p className="mt-3">
              Click on the particular method, enter amount and click “Submit”
              Done you will receive your funds in your account with in some time
              after verification.
            </p>
          </div>
        </div>
      </main>

      {!isAuth ? <BeforeLoginFooter /> : <Footer />}
    </>
  );
};

export default HowToWithdraw;
