import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { gatewayFormValidator } from "../../../../utils/gatewayFormValidator";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import QRCodeCard from "../../../../utils/QrCodeCard";
import {
  updateDepositAmount,
  createDeposit,
} from "../../../../redux/user/actions";
import { APP_CONST } from "../../../../config/const";
import { Toaster } from "../../../../utils/toaster";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import { Container } from "react-bootstrap";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { getActiveBankDetails } from "../../../../redux/app/actions";
// import CopyToClipboard from "../../../components/copyToClipBoard";
import CopyToClipboard from "react-copy-to-clipboard";
import BackIcon from "../../../../assets/svg/BackIcon";
import ArrowNext from "../../../../assets/images/arrow-next.png";
import { AiFillCopy } from "react-icons/ai";
import LeftBarSports from "../../../components/LeftBarSports";
import numberWithCommas from "../../../../utils/numbersWithComma";
import CrossBtn from "../../../../assets/images/icons/close_btn-icon.png";
import QR from "../../../../assets/images/QR.jpg";
const CUSTOM_DEPOSIT_RESPONSE_TYPE = {
  QRCODE: "QRCODE",
  IMAGE: "IMAGE",
};
const DepositGateway = () => {
  const { selectedGateway, gatewaysList } = useSelector((state) => state.user);
  const { bankDetails } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.auth);

  const { appDetails } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const { gateway } = useParams();
  const isBank = gateway === "bank_transfer";
  const navigate = useNavigate();
  const [dAmount, setDAmount] = useState(null);
  const amountValues = [1000, 5000, 10000, 50000, 100000, 500000];
  const showQrImg = selectedGateway?.qrCode !== null;
  const [customdepositResponseType, setCustomDepositResponseType] =
    useState(null);
  const [customActionDepositResponse, setCustomActionDepositResponse] =
    useState(null);
  useEffect(() => {
    dispatch(getActiveBankDetails());
  }, []);
  // console.log("selectedGateway", selectedGateway);
  const validateData = (dataToValidate) => {
    let fields = selectedGateway.fields;
    let validations = gatewayFormValidator(fields, dataToValidate);
    setErrors(validations);
    return validations;
  };
  const validateBankFormData = (data) => {
    const { amount, utrNo, txnImage } = data;
    let errorVar = {};
    if (!amount) {
      errorVar.amount = "Amount is required";
    }
    if (!utrNo) {
      errorVar.utrNo = "UTR is required";
    }
    if (!txnImage) {
      errorVar.txnImage = "Txn image is required";
    }
    setErrors(errorVar);
    return errorVar;
  };
  const handleChange = (e, type = "non-file") => {
    let name = e.target.name;
    let value = e.target.value;
    if (type === "file") {
      value = e.target.files?.[0];
    }
    let data = { ...formData, [name]: value };
    if (name === "amount") {
      setDAmount(value);
    }
    if (isBank) {
      validateBankFormData(data);
    } else {
      validateData(data);
    }

    setFormData(data);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isBank) {
      if (Object.values(validateData({ ...formData })).length == 0) {
        // console.log("formData", formData);
        if (
          selectedGateway.fields.some(
            (f) => f?.accessor === "gateway" && f?.required === true
          )
        ) {
          formData["gateway"] = selectedGateway?.code;
        }
        if (selectedGateway?.slug && selectedGateway?.slug !== null) {
          formData.slug = selectedGateway?.slug;
        }
        const response = await updateDepositAmount({
          data: formData,
          url: selectedGateway?.url,
          isMultiPart: selectedGateway?.fields.some((f) => f?.type === "file"),
        });
        handleSubmitResponse(response);
        // console.log("response", response);
      }
    } else {
      if (Object.values(validateBankFormData(formData)).length === 0) {
        createDeposit(formData)
          .then((res) => {
            Toaster(APP_CONST.TOAST_TYPE.SUCCESS, res.data.data.message);
          })
          .catch((err) => {
            Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
          });
      }
    }
  };

  //TODO  Add it for url
  const handleSubmitResponse = (value) => {
    if (value) {
      if (selectedGateway.type === "manual") {
        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, value.data.data.message);
      } else if (value?.data?.data?.data && "payUrl" in value?.data.data.data) {
        // Redirect url
        window.location.href = value?.data?.data?.data?.payUrl;
      } else if (
        value?.data?.data?.data &&
        "payImageUrl" in value?.data.data.data
      ) {
        // Show image
        setCustomActionDepositResponse(value?.data?.data?.data?.payImageUrl);
        setCustomDepositResponseType(CUSTOM_DEPOSIT_RESPONSE_TYPE.IMAGE);
      } else if (
        value?.data?.data?.data &&
        "qrContent" in value?.data.data.data
      ) {
        // Show qr code
        setCustomActionDepositResponse(value?.data?.data?.data?.qrContent);
        setCustomDepositResponseType(CUSTOM_DEPOSIT_RESPONSE_TYPE.QRCODE);
      } else if (value?.data?.data?.data && "url" in value?.data.data.data) {
        // Redirect url
        window.location.href = value?.data?.data?.data?.url;
      } else if (selectedGateway.code === "alphapo") {
        window.location.href = value?.data?.data?.data?.url;
      } else if (selectedGateway.code === "coinbase") {
        window.location.href = value?.data?.data?.data?.url;
      }
    }
  };

  const renderFields = (field) => {
    switch (field.type) {
      case "file":
        return (
          <Form.Group
            className="mb-3 position-relative"
            controlId="formBasicUserName"
          >
            <Form.Control
              type={field.type}
              placeholder={field.label}
              name={field.accessor}
              onChange={(e) => handleChange(e, "file")}
              style={{ display: !field.display ? "none" : "" }}
              isInvalid={!!errors[field.accessor]}
            />
            <span className="focus-border">
              <i></i>
            </span>
            <Form.Control.Feedback type="invalid">
              {errors[field.accessor]}
            </Form.Control.Feedback>
          </Form.Group>
        );
      default:
        return (
          <>
            {selectedGateway.type === "auto" && (
              <div className="mb-4">
                <ul className="select-amount">
                  {amountValues.map((item) => (
                    <li
                      onClick={() => {
                        setDAmount(item);
                        setFormData({ ...formData, amount: item });
                      }}
                    >
                      INR {numberWithCommas(item)}
                    </li>
                  ))}
                  {selectedGateway?.type === "auto" && (
                    <li className="fill-amount">
                      <Form.Group
                        className="position-relative"
                        controlId="formBasicUserName"
                      >
                        <Form.Control
                          type={field.type}
                          value={dAmount || ""}
                          placeholder={field.label}
                          name={field.accessor}
                          onChange={handleChange}
                          style={{ display: !field.display ? "none" : "" }}
                          isInvalid={!!errors[field.accessor]}
                        />
                        <span className="inr">INR</span>
                        <Form.Control.Feedback type="invalid">
                          {errors[field.accessor]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </li>
                  )}
                </ul>
              </div>
            )}
            {selectedGateway?.type === "manual" && (
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicUserName "
              >
                <Form.Control
                  type={field.type}
                  // value={dAmount || ""}
                  placeholder={field.label}
                  name={field.accessor}
                  onChange={handleChange}
                  style={{ display: !field.display ? "none" : "" }}
                  isInvalid={!!errors[field.accessor]}
                />
                <span class="focus-border">
                  <i></i>
                </span>
                <Form.Control.Feedback type="invalid">
                  {errors[field.accessor]}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            {selectedGateway.type === "auto" && (
              <p className="min-max">
                <b>Min</b> <small>INR</small>1,000 - <b>Max</b>{" "}
                <small>INR</small>100,0000
              </p>
            )}
          </>
        );
    }
  };

  const renderBankTransferForm = () => {
    return (
      <Form className="gatewayForm">
        <Form.Group
          className="mb-3 position-relative"
          controlId="formBasicUserName"
        >
          <Form.Control
            type={"number"}
            placeholder={"Enter amount"}
            name={"amount"}
            onChange={(e) => handleChange(e)}
            // style={{ display: !field.display ? "none" : "" }}
            isInvalid={!!errors["amount"]}
          />
          <span className="focus-border">
            <i></i>
          </span>
          <Form.Control.Feedback type="invalid">
            {errors["amount"]}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          className="mb-3 position-relative"
          controlId="formBasicUserName"
        >
          <Form.Control
            type={"text"}
            placeholder={"Enter UTR no"}
            name={"utrNo"}
            onChange={(e) => handleChange(e)}
            // style={{ display: !field.display ? "none" : "" }}
            isInvalid={!!errors["utrNo"]}
          />
          <span className="focus-border">
            <i></i>
          </span>
          <Form.Control.Feedback type="invalid">
            {errors["utrNo"]}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          className="mb-3 position-relative"
          controlId="formBasicUserName"
        >
          <Form.Control
            type={"file"}
            placeholder={"Select file"}
            name={"txnImage"}
            onChange={(e) => handleChange(e, "file")}
            // style={{ display: !field.display ? "none" : "" }}
            isInvalid={!!errors["txnImage"]}
          />
          <span className="focus-border">
            <i></i>
          </span>
          <Form.Control.Feedback type="invalid">
            {errors["txnImage"]}
          </Form.Control.Feedback>
        </Form.Group>
        <Button
          className="px-5 mb-4"
          variant="primary"
          onClick={handleSubmit}
          style={{ fontSize: "15px", borderRadius: "3px" }}
        >
          Submit
        </Button>
      </Form>
    );
  };
  const renderCustomActionResponse = () => {
    if (customdepositResponseType === CUSTOM_DEPOSIT_RESPONSE_TYPE.IMAGE) {
      return (
        <div>
          <div className="ml-4 ">
            <div className="bg-zinc-50 rounded-lg p-3 qrSize">
              <img src={customActionDepositResponse} />
            </div>
          </div>
        </div>
      );
    } else if (
      customdepositResponseType === CUSTOM_DEPOSIT_RESPONSE_TYPE.QRCODE
    ) {
      return (
        <div>
          <QRCodeCard text={customActionDepositResponse} />
        </div>
      );
    } else {
      return null;
    }
  };
  return (
    <>
      <HeaderAfterLogin />
      {customActionDepositResponse && customdepositResponseType ? (
        <>
          <div className="HpaySection">
            <div
              className="HayQR"
              onClick={() => {
                setCustomActionDepositResponse(null);
                setCustomDepositResponseType(null);
              }}
            >
              <img src={CrossBtn} alt="CrossBtn" />
            </div>
            {renderCustomActionResponse()}
          </div>
        </>
      ) : (
        <main className="main mt-5">
          <div className="d-xl-none mt--14">
            <LeftBarSports />
          </div>
          {!isBank ? (
            <Container>
              <div className=" headingSec">
                {" "}
                <h4>{selectedGateway?.name}</h4>{" "}
                <div className="back-link" onClick={() => navigate(-1)}>
                  <BackIcon /> Back
                </div>
              </div>
              {selectedGateway?.type === "manual" && (
                <>
                  <div className="valuesDeposit">
                    {/* <h3>Wallet : {selectedGateway?.value}</h3> */}
                    <h3>UPI : {selectedGateway?.displayValue}</h3>

                    <div className="copyValue">
                      <CopyToClipboard
                        text={selectedGateway?.displayValue}
                        onCopy={() => {
                          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                        }}
                      >
                        <AiFillCopy />
                      </CopyToClipboard>
                      {/* <CopyToClipboard
                      text={selectedGateway?.value?.slice(index1, index2)}
                      onCopy={() => {
                        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                      }}
                    /> */}
                      <div>Click to copy code</div>
                    </div>
                  </div>
                  {(selectedGateway?.showQr === true && (
                    <div className="d-flex">
                      {showQrImg ? (
                        <div className=" mx-auto  w-full max-w-[12rem]  relative">
                          <div className="ml-4 ">
                            <div className="bg-zinc-50 rounded-lg p-3 qrSize">
                              <img src={selectedGateway?.qrCode} />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <QRCodeCard text={selectedGateway?.value} />
                      )}
                    </div>
                  )) ||
                    null}
                </>
              )}

              <Form className="gatewayForm">
                {selectedGateway?.fields?.map((f, i) => {
                  return renderFields(f);
                })}
                <span className="focus-border">
                  <i></i>
                </span>
                <Button
                  className={
                    selectedGateway.type === "auto" ? "next-btn" : "px-4 mb-4"
                  }
                  onClick={handleSubmit}
                  style={{ fontSize: "15px", borderRadius: "3px" }}
                >
                  {selectedGateway.type !== "auto" ? "Submit" : "Next"}
                  {selectedGateway.type === "auto" && (
                    <img src={ArrowNext} alt="arrow next" />
                  )}
                </Button>
                {selectedGateway.type === "auto" && (
                  <div className="info-text">
                    <p className="after-submit">
                      After clicking the Submit button you will be redirected to
                      the payment method where you will be able to make payment
                      in the desired currency of your choice like (e.g.
                      BTC,USDT,ETH)
                    </p>

                    <p className="note">
                      <span>Note:</span> In case you face any issue with payment
                      method or amount is not credit to your account contact us
                      immediately through the <b>live chat</b> or{" "}
                      <b>whatsapp</b>
                    </p>

                    <ul className="contact">
                      <li>
                        <a
                          className="wtsp"
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                              : "javascript:void(0)"
                          }
                          target={
                            appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                          }
                        >
                          Whatsapp
                        </a>
                      </li>
                      <li>
                        <a className="cntct" href="/contactus">
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </Form>
            </Container>
          ) : (
            <Container>
              <div className="headingSec">
                {"Bank Transfer"}
                <div className="back-link" onClick={() => navigate(-1)}>
                  <BackIcon /> Back
                </div>
              </div>
              {bankDetails && (
                <div className="bankDetailsQR">
                  <div
                    className="mb-4"
                    style={{
                      background: "#111",
                      padding: "15px",
                      borderRadius: "5px",
                    }}
                  >
                    <p>
                      Account name : {bankDetails?.acc_name}{" "}
                      <CopyToClipboard
                        text={bankDetails?.acc_name}
                        onCopy={() => {
                          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                        }}
                      >
                        <AiFillCopy />
                      </CopyToClipboard>
                      {/* <CopyToClipboard
                    text={bankDetails?.acc_name}
                    onCopy={() => {
                      Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                      console.log("Abc");
                    }}
                  /> */}
                    </p>
                    <p>
                      Account number : {bankDetails?.acc_no}{" "}
                      <CopyToClipboard
                        text={bankDetails?.acc_no}
                        onCopy={() => {
                          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                        }}
                      >
                        <AiFillCopy />
                      </CopyToClipboard>
                      {/* <CopyToClipboard
                    text={bankDetails?.acc_no}
                    onCopy={() => {
                      Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                    }}
                  /> */}
                    </p>
                    <p>
                      IFSC code : {bankDetails?.ifsc_code}{" "}
                      <CopyToClipboard
                        text={bankDetails?.ifsc_code}
                        onCopy={() => {
                          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                        }}
                      >
                        <AiFillCopy />
                      </CopyToClipboard>
                      {/* <CopyToClipboard
                    text={bankDetails?.ifsc_code}
                    onCopy={() => {
                      Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                    }}
                  /> */}
                    </p>
                    <p>
                      Bank name : {bankDetails?.bank_name}{" "}
                      <CopyToClipboard
                        text={bankDetails?.bank_name}
                        onCopy={() => {
                          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                        }}
                      >
                        <AiFillCopy />
                      </CopyToClipboard>
                      {/* <CopyToClipboard
                    text={bankDetails?.bank_name}
                    onCopy={() => {
                      Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                    }}
                  /> */}
                    </p>
                  </div>
                  <div className="QR">
                    <img src={QR} />
                  </div>
                </div>
              )}
              {bankDetails && renderBankTransferForm()}
            </Container>
          )}
        </main>
      )}
      <Footer />
    </>
  );
};
export default DepositGateway;
