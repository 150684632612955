import React, { PureComponent } from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";

import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";
function BeforeFairPlay() {
  const navigate = useNavigate();
  return (
    <>
      <Header />

      <main className="main static-page">
        <div className="container">
          <div className="headingSec">
            <h4>Fair Play</h4>
            <div className="back-link" onClick={() => navigate(-1)}>
              <BackIcon /> Back
            </div>
          </div>
          <div className="content">
            <h5>Equally and Respectfully</h5>
            <p>
              Guru111 treats all Players equally and respectfully. Guru111
              Provide Fair Odds and Fair winnings. Fair competition, respect,
              friendship, team spirit, equality, sport without doping, respect
              for written and unwritten rules such as integrity, solidarity,
              tolerance, care, excellence and joy, are the building blocks of
              fair play that can be experienced and learnt both on and off the
              field.
            </p>
          </div>
        </div>
      </main>

      <BeforeLoginFooter />
    </>
  );
}

export default BeforeFairPlay;
