export const gatewayFormValidator = (fields = [], dataToValidate = {}) => {
  let errorObject = {};
  fields.forEach((f) => {
    if (f.required && f.display) {
      if (dataToValidate?.[f?.accessor]) {
        let value = dataToValidate?.[f?.accessor];
        if (!Boolean(value)) {
          errorObject[f?.accessor] = `${f?.accessor} is required`;
        }
        // else if (value < 1000) {
        //   errorObject[f?.accessor] = `Minimum deposit amount is 1000`;
        // }
      } else {
        errorObject[f?.accessor] = `${f?.accessor} is required`;
      }
    }
  });
  return errorObject;
};
