import React from "react";
import { useNavigate } from "react-router-dom";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import BackIcon from "../../../../assets/svg/BackIcon";
import Spribe1 from "../../../../assets/images/spribe/Aviator.jpg";
import Spribe2 from "../../../../assets/images/spribe/Dice.jpg";
import Spribe3 from "../../../../assets/images/spribe/Goal.jpg";
import Spribe4 from "../../../../assets/images/spribe/Hilo.jpg";
import Spribe5 from "../../../../assets/images/spribe/Mine.jpg";
import Spribe6 from "../../../../assets/images/spribe/MiniROulette.jpg";

const Spribe = () => {
  const navigate = useNavigate();

  return (
    <>
      <HeaderAfterLogin />

      <main className="main">
        <div className="spribe-page">
          <div className="headingSec">
            <h4>Spribe</h4>
            <div className="back-link" onClick={() => navigate(-1)}>
              <BackIcon /> Back
            </div>
          </div>

          <div className="games-list">
            <ul>
              <li>
                <a href="/casino/spribe/aviator">
                  <img src={Spribe1} alt="Live casino game" />
                </a>
              </li>
              <li>
                <a href="/casino/spribe/dice">
                  <img src={Spribe2} alt="Live casino game" />
                </a>
              </li>
              <li>
                <a href="/casino/spribe/goal">
                  <img src={Spribe3} alt="Live casino game" />
                </a>
              </li>
              <li>
                <a href="/casino/spribe/hilo">
                  <img src={Spribe4} alt="Live casino game" />
                </a>
              </li>
              <li>
                <a href="/casino/spribe/mines">
                  <img src={Spribe5} alt="Live casino game" />
                </a>
              </li>
              <li>
                <a href="/casino/spribe/miniroulette">
                  <img src={Spribe6} alt="Live casino game" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Spribe;
